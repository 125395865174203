
import { defineComponent, reactive, toRefs, ref } from 'vue'
import detailLayout from '@/components/detailLayout/index.vue'
import FormItem from '@/components/formItem/index.vue'
import { useRoute } from 'vue-router'
import proposalApi from '@/axios/api/proposal'
import { message } from 'ant-design-vue'
import Data from '@/model/common/Data'
import { Proposal } from '@/model/industryManagement/proposal'
import { useStore } from 'vuex'
import { FormItemListType, MissingType } from '@/types/comm'

export default defineComponent({
  components: { detailLayout, FormItem },
  setup() {
    const formRef = ref()
    //获取store
    const store = useStore()
    const buttonObj = store.getters.getButtonObj
    let checkPhone = async (rule: string, value: string) => {
      if (!value) {
        return Promise.reject('请输入手机号')
      }
      if (!/^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/.test(value)) {
        return Promise.reject('手机号格式不正确')
      } else {
        return Promise.resolve()
      }
    }
    const pageData = reactive({
      previewIndex: null as number | null,
      previewUrl: [] as string[],
      formItemList: [
        {
          type: 'subInput',
          prop: 'demandNo',
          placeholder: '请输入需求编号',
          label: '需求编号',
          disabled: true,
          isShow: true,
        },
        {
          type: 'subInput',
          prop: 'submitTime',
          placeholder: '请选择提交时间',
          label: '提交时间',
          disabled: true,
          isShow: true,
        },
        {
          type: 'subInput',
          prop: 'createdTime',
          placeholder: '请选择提交时间',
          label: '转提案时间',
          disabled: true,
          isShow: true,
        },
        {
          type: 'slot',
          prop: 'contact',
          placeholder: '请选择联系人',
          label: '联系人',
          disabled: buttonObj['demand-update-all'] ? false : true,
          isShow: buttonObj['demand-checkPhone'] ? true : false,
        },
        {
          type: 'slot',
          prop: 'contactPhone',
          placeholder: '请输入联系电话',
          label: '联系电话',
          disabled: buttonObj['demand-update-all'] ? false : true,
          isShow: buttonObj['demand-checkPhone'] ? true : false,
        },
        {
          type: 'subInput',
          prop: 'companyName',
          placeholder: '请输入公司名称',
          label: '公司名称',
          disabled: buttonObj['demand-update-all'] ? false : true,
          isShow: true,
        },
        {
          type: 'subInput',
          prop: 'demandProduct',
          placeholder: '请输入产品名称',
          label: '产品名称',
          disabled: buttonObj['demand-update-all'] ? false : true,
          isShow: true,
        },
        {
          type: 'subInput',
          prop: 'deviceName',
          placeholder: '请输入应用设备名称',
          label: '应用设备名称',
          disabled: buttonObj['demand-update-all'] ? false : true,
          isShow: true,
        },
        {
          type: 'numberInput',
          prop: 'demandCount',
          placeholder: '请输入产品预计年需求量',
          label: '产品预计年需求量',
          disabled: buttonObj['demand-update-all'] ? false : true,
          isShow: true,
        },

        {
          type: 'subDate',
          prop: 'demandDate',
          placeholder: '请输入客户需求时间',
          label: '客户需求时间',
          valueFormat: 'YYYY-MM-DD 00:00:00',
          disabled: buttonObj['demand-update-all'] ? false : true,
          isShow: true,
        },
        // {
        //   type: 'subInput',
        //   prop: 'demandDate',
        //   placeholder: '请输入转提案时间',
        //   label: '转提案时间',
        //   isShow: true,
        // },
        {
          type: 'slot',
          prop: 'demandDate',
          placeholder: '请输入需求时间',
          label: '',
          valueFormat: 'YYYY-MM-DD 00:00:00',
          disabled: buttonObj['demand-update-all'] ? false : true,
          isShow: buttonObj['demand-checkPhone'] ? true : false,
          span: 18,
        },
        {
          type: 'textarea',
          prop: 'useEnv',
          placeholder: '请输入产品的使用场景',
          label: '产品的使用场景',
          disabled: buttonObj['demand-update-all'] ? false : true,
          isShow: true,
          autoSize: { minRows: 5, maxRows: 6 },
          maxlength: 1000,
          span: 12,
        },
        {
          type: 'textarea',
          prop: 'demandDesc',
          placeholder: '如产品质量改善、竞品问题避免、同类产品迭代质量改善',
          label: '历史问题或者产品质量改善要求',
          span: 12,
          disabled: buttonObj['demand-update-all'] ? false : true,
          autoSize: { minRows: 5, maxRows: 6 },
          maxlength: 1000,
          isShow: true,
        },
        {
          type: 'slot',
          prop: 'uploadFile',
          placeholder: '上传文件',
          label: '上传文件',
          span: 24,
          isShow: true,
        },
      ] as FormItemListType<Proposal>,
      detailForm: { status: 0 } as Proposal,
      fileList: [] as MissingType[],
      //表单数据
      rules: {
        companyName: [{ required: true, message: '公司名称不可为空', trigger: 'change' }],
        contact: [{ required: true, message: '联系人不可为空', trigger: 'change' }],
        contactPhone: [{ required: true, validator: checkPhone, trigger: 'change' }],
        demandProduct: [{ required: true, message: '需求产品不可为空', trigger: 'change' }],
        // deviceName: [{ required: true, message: '设备名称不可为空', trigger: 'change' }],
        demandCount: [
          { required: true, message: '预计需求数量不可为空', type: 'number', trigger: 'change' },
        ],
        useEnv: [{ required: true, message: '使用环境不可为空', trigger: 'change' }],
        demandDate: [{ required: true, message: '需求时间不可为空', trigger: 'change' }],
      },
      stepsItem: [
        {
          title: '转提案',
          acriveStatus: 0,
          icon: 'icon-zhuantian',
          isShow: true,
          time: '',
        },
        {
          title: '评估中',
          acriveStatus: 1,
          icon: 'icon-pingguzhong',
          isShow: true,
          time: '',
        },
        {
          title: '开发中',
          acriveStatus: 2,
          icon: 'icon-kaifazhong',
          isShow: true,
          time: '',
        },
        {
          title: '报价中',
          acriveStatus: 3,
          icon: 'icon-baojiazhong',
          isShow: true,
          time: '',
        },
        {
          title: '已完成',
          acriveStatus: 4,
          icon: 'icon-yiwancheng',
          isShow: true,
          time: '',
        },
        {
          title: '已终止',
          acriveStatus: 5,
          icon: 'icon-yizhongzhi',
          isShow: false,
          time: '',
        },
      ],
      removeFile: [] as string[],
    })
    let route = useRoute()
    const getDetail = () => {
      proposalApi
        .GetByNo({ demandNo: route.params.id as string })
        .then((res) => {
          pageData.detailForm = res
          console.log(pageData.detailForm, 'pageData.detailForm')
          //先重置
          pageData.stepsItem.forEach((item, index) => {
            item.isShow = index === 5 ? false : true
            if (index === 0) item.time = pageData.detailForm.createdTime as string //转提案时间
            if (index === 1) item.time = pageData.detailForm.assigneeDate as string //评估中时间
            if (index === 2) item.time = pageData.detailForm.estimatedTime as string //开发中时间
            if (index === 3) item.time = pageData.detailForm.finishDate as string //报价中时间
            if (index === 4) item.time = pageData.detailForm.finishDate as string //已完成
            if (index === 5) item.time = pageData.detailForm.updatedTime as string //终止
          })
          //判断是否终止 显示终止按钮去除已完成 报价中状态显示
          if (res.status === 5) {
            pageData.stepsItem[3].isShow = false
            pageData.stepsItem[4].isShow = false
            pageData.stepsItem[5].isShow = true
            //判断是否有评估人  无评估去除开发中
            if (!res.assessor) {
              pageData.stepsItem[2].isShow = false
              //判断是否有分配  无评估去除评估中
              if (!res.assignee) {
                pageData.stepsItem[1].isShow = false
              }
            }
          }
          pageData.fileList =
            (res.fileInfoList as Data[])?.map((item) => {
              return {
                uid: item.id as string,
                type: item.fileSuffix as string,
                name: item.fullFileName as string,
                fullUrl: item.fullUrl as string,
              }
            }) || []
        })
        .catch((err) => {
          message.error(err)
        })
    }
    getDetail()
    const deleteFile = (fileitem: FileItem, index: number) => {
      pageData.removeFile.push(fileitem.uid)
      pageData.fileList.splice(index, 1)
    }
    interface FileItem {
      uid: string
      name?: string
      status?: string
      response?: string
      url?: string
      preview?: string
      size: number
      file: string | Blob | File | FileItem
      type: string
    }
    const customRequest = (param: FileItem) => {
      console.log('上传', param)
      if ((param.file as FileItem).size / 1024 / 1024 > 10) {
        message.error('单个上传文件不得大于10M！')
        return
      }
      const formData = new FormData()
      formData.append('file', param.file as Blob)
      proposalApi
        .uploadFile(formData)
        .then((res) => {
          const result = res as unknown as { id: string }
          pageData.fileList.push({
            uid: result.id,
            type: (param.file as FileItem).type,
            name: (param.file as FileItem).name,
          })
        })
        .catch((err) => {
          console.log(err)

          message.error(err || '上传失败')
        })
    }
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          pageData.detailForm.fileIdList = pageData.fileList.map((item) => {
            return item.uid as string
          })
          proposalApi
            .DemandUpdate(pageData.detailForm)
            .then(() => {
              message.success('修改成功')
              getDetail()
            })
            .catch((err) => {
              message.error(err || '修改失败')
            })
          pageData.removeFile.forEach((item) => {
            proposalApi.FileDelete({ id: item }).then(() => {
              console.log('删除成功')
            })
          })
          pageData.removeFile = []
        })
        .catch(() => {
          console.log('未通过', pageData.detailForm)
        })
    }
    // 下载文件
    const downloadFile = (fileitem: Data) => {
      const a = document.createElement('a')
      a.download = fileitem.name as string
      a.href = `/api/file/preview?id=${fileitem.uid}`
      a.click()
    }

    const isImage = (filename: string) => {
      const imageTypes = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'] // 支持的图片格式
      const ext = filename.substring(filename.lastIndexOf('.')).toLowerCase()
      return imageTypes.indexOf(ext) > -1
    }

    // 预览文件
    const preview = (fileitem: Data, index: number) => {
      proposalApi
        .DemanddownloadSec(fileitem.uid as string)
        .then((res) => {
          const blob = new Blob([res], { type: 'image/png' })
          const imageUrl = URL.createObjectURL(blob)
          pageData.previewUrl[index] = imageUrl
          pageData.previewIndex = index
        })
        .catch(() => {
          message.error('预览失败')
        })
    }

    const setVisible = (index: number | null) => {
      if (index === null) {
        URL.revokeObjectURL(pageData.previewUrl[pageData.previewIndex as number])
      }
      pageData.previewIndex = index
    }

    return {
      downloadFile,
      formRef,
      onSubmit,
      customRequest,
      ...toRefs(pageData),
      getDetail,
      deleteFile,
      buttonObj,
      isImage,
      preview,
      setVisible,
    }
  },
})
